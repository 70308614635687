import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { CommandManagerService }  from '../../../../command-manager.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-shutter-list-item',
  templateUrl: './shutter-list-item.component.html',
  styleUrls: ['./shutter-list-item.component.sass'],
  inputs: [
    'device'
  ]
})
export class ShutterListItemComponent implements AfterViewInit {
  device;
  typeName; 
  @ViewChild('bgColor', {static: false}) bgColor; 


  constructor(private commandManager: CommandManagerService, private translate: TranslateService) { }
  
  ngAfterViewInit() {
    this.typeName = this.translate.instant('devices.type' + this.device.lampType.toString());
    const color = this.device.lampBgColor >= 0 ? this.device.lampBgColor : 0
    this.bgColor.nativeElement.style.backgroundColor = this.translate.instant('devices.bgColor' + color.toString());

  }

  open(){
    this.commandManager.sendBrightnessCommand(this.device.ownKey,255);
  }

  stop(){
    this.commandManager.sendBrightnessCommand(this.device.ownKey,3);
  }

  close(){
    this.commandManager.sendBrightnessCommand(this.device.ownKey,0);
  }

}
