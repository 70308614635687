import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import {MatSnackBar} from '@angular/material';

import {TranslateService} from '@ngx-translate/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { BlueluxDataService }  from '../../../bluelux-data.service';

@Component({
  selector: 'app-api-login',
  templateUrl: './api-login.component.html',
  styleUrls: ['./api-login.component.scss'],

})
export class ApiLoginComponent implements OnInit {

  @Output() onLoginStatusChanged = new EventEmitter<boolean>();
  loggedIn = false;
  showEmailDialog = false;

  constructor(public af: AngularFireAuth, public snackBar: MatSnackBar, private translate: TranslateService, private activatedRoute: ActivatedRoute, private data: BlueluxDataService) {
     
  }

  /*
    Google Assistant Login Component
    Receive the Oauth2 authorizationCode request parameters using get query strings.

    state, client_id, response_type, scope, and redirect_uri as query string parameters.
    The state is used by the Alexa service during the account linking process. Your page needs to keep track of this value, as you must pass it back later.
    The client_id is a value defined by you when you set up account linking for the skill in the developer portal.
    The response_type is always code for the code grant flow.
    The scope is an optional list of access scopes indicating the level of access requested. You define the set of scopes to support when enabling account linking for your skill.
    The redirect_uri is the URL to which your service redirects the user once the user is authenticated.

    Ask for user authentication.

    Write the parameters on Data service to be used on Link Component.

  */
 
  ngOnInit() { 
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        let state = params['state'];
        let client_id = params['client_id'];
        let response_type = params['response_type'];
        let scope = params['scope'];
        let redirect_uri = params['redirect_uri'];

        if(!scope){
          scope = 'std';
        }

        this.data.alexa_state = state;
        this.data.alexa_client_id = client_id;
        this.data.alexa_response_type = response_type;
        this.data.alexa_scope = scope;
        this.data.alexa_redirect_uri = redirect_uri;


        console.log('Api parameters')
        console.log(state)
        console.log(client_id)
        console.log(response_type)
        console.log(scope)
        console.log(redirect_uri)        

      });

  } 

  loginWithGoogle() {
     this.af.auth.signInWithPopup(
     new firebase.auth.GoogleAuthProvider()
    ).catch(e=>{
      console.log("Error trying to log in with your Google Account.")
      this.openSnackBar(this.translate.instant('alexa-login.googleSignInError'));
    });
  }


  loginWithEmail() {
    this.showEmailDialog = true;
    /*this.af.auth.signInWithPopup(
    new firebase.auth.EmailAuthProvider()
   ).catch(e=>{
     console.log("Error trying to log in with your Email Account.")
     this.openSnackBar(this.translate.instant('alexa-login.emailSignInError'));
   });*/

   
   
 }


  loginWithFacebook() {
    this.af.auth.signInWithPopup(
           new firebase.auth.FacebookAuthProvider()
    ).catch(e=>{
      console.log("Error trying to log in with your Facebook Account.")
      this.openSnackBar(this.translate.instant('alexa-login.facebookSignInError'));
    });
  }

  cancelEmailSignIn(){
    this.showEmailDialog = false;    
  }

  tryEmailCredentialsSignIn(email:string, password:string){
    console.log("Email " + email);
    console.log("Password " + password);
    this.af.auth.signInWithEmailAndPassword(email,password).catch(e=>{
      console.log("Error trying to log in with your Email Account.")
      this.openSnackBar(this.translate.instant('alexa-login.emailSignInError'));
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

}
