import { Injectable } from '@angular/core';
import { BlueluxDataService }  from './bluelux-data.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import * as firebase from 'firebase';

@Injectable()
export class CommandManagerService {

  constructor(public af: AngularFireDatabase,private data: BlueluxDataService) { 
  }

   COMMAND_SETBRIGHTNESS = "setbrightness";
   COMMAND_SETSCENE = "setscene";
  


   sendBrightnessCommand(deviceKey,brightness){
        // /commands/$placeId/$device/

        var object = {};
        object['targetKey'] = deviceKey; 
        object['type'] = this.COMMAND_SETBRIGHTNESS;
        object['value'] = brightness;



        const itens = this.af.list('/commands/'.concat(this.data.selectedPlace.ownKey));
        itens.push(object);

   }

   sendSceneCommand(sceneKey){
        // /commands/$placeId/$device/

        var object = {};
        object['targetKey'] = sceneKey;
        object['type'] = this.COMMAND_SETSCENE;
        object['value'] = 255;


        const itens = this.af.list('/commands/'.concat(this.data.selectedPlace.ownKey));
        itens.push(object);
   }

   updateTimeflow(timeflow){
     const itemObservable = this.af.object('/timeflows/'.concat(this.data.selectedPlace.ownKey).concat("/").concat(timeflow.sceneKey).concat("/").concat(timeflow.ownKey));
     //Discard not-persistable fields
     timeflow.ownKey = null;
     timeflow.sun = null;
     timeflow.mon = null;
     timeflow.tue = null;
     timeflow.wed = null;
     timeflow.thu = null;
     timeflow.fri = null;
     timeflow.sat = null;

     console.log("update timeflow")
     console.log(timeflow)
     itemObservable.update(timeflow);
   };

   createAuthorizationCode() {
    var authCode = Math.random().toString(36).substr(2) + '' + Math.random().toString(36).substr(2) + '' + Math.random().toString(36).substr(2);
    return authCode;
   }



   writeAuthorizationCode(redirect_uri,state, place, client_id,scope, authCode) {
        //http://localhost:4200/alexa-login?client_id=alexa-bluelux-skill&state=blabla&response_type=bla&scope=bla&redirect_uri=https%3A%2F%2Flayla.amazon.com%2Fapi%2Fskill%2Flink%2FMNPUTGUI0O4S2
        //http://localhost:4200/google-assistant-login?client_id=google-assistant-bluelux-action&state=blabla&response_type=bla&scope=bla&redirect_uri=https%3A%2F%2Foauth-redirect.googleusercontent.com%2Fr%2Fbluelux-smart-home-action
        //http://localhost:4200/api-login?client_id=tovtec&state=blabla&response_type=bla&scope=bla&redirect_uri=https%3A%2F%2testeteste
        // /oauth2/authenticationCode/$authCodeId/
        
        var object = {}; 
        object['uid'] = this.data.uid;
        object['placeKey'] = place.ownKey;         
        object['timestamp'] = firebase.database.ServerValue.TIMESTAMP;
        object['redirectUri'] = encodeURIComponent(redirect_uri);
        object['scope'] = scope;
        object['clientId'] = client_id;

        console.log('PlaceKey');
        console.log(place.ownKey);
        console.log(object['timestamp']);
        console.log(object['clientId']);
        console.log(object['redirectUri']);

            
  
        const itens = this.af.object('/oauth2/authorizationCodes/'.concat(authCode));
        const promise = itens.set(object);

        return promise;
        /*promise
        .then(_ =>  {
          console.log(redirect_uri+'?state='+state+'&code='.concat(authCode))
           location.href  = redirect_uri+'?state='+state+'&code='.concat(authCode);           
          }
        )
        .catch(err => console.log(err, 'Error creating authorizationCode!'));*/
      
   }

}

// generateId :: Integer -> String
function generateId (len) {
  var arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(('0' + arr.toString()).substr(-2)).join('')
}