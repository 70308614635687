import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { CommandManagerService }  from '../../../../command-manager.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-door-list-item',
  templateUrl: './door-list-item.component.html',
  styleUrls: ['./door-list-item.component.sass'],
  inputs: [
    'device'
  ]
})
export class DoorListItemComponent implements AfterViewInit {
  device;
  @ViewChild('bgColor', {static: false}) bgColor; 


  constructor(private commandManager: CommandManagerService, private translate: TranslateService) { }
  
  ngAfterViewInit() {
    const color = this.device.lampBgColor >= 0 ? this.device.lampBgColor : 0
    this.bgColor.nativeElement.style.backgroundColor = this.translate.instant('devices.bgColor' + color.toString());
    }

  open(){
    this.commandManager.sendBrightnessCommand(this.device.ownKey,255);
  }

}
