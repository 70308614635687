import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { BlueluxDataService } from '../../bluelux-data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private data: BlueluxDataService) { }
  @ViewChild('gridview', {static: false}) gridview;


  ngOnInit() {
    if (window.innerWidth > 370)
      this.gridview.nativeElement.style.paddingLeft = ((window.innerWidth % 370) / 2).toString().concat("px");
    else
      this.gridview.nativeElement.style.paddingLeft = (0).toString().concat("px");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event.target.innerWidth);
    //console.log((event.target.innerWidth/348)/2);
    console.log(this.gridview.nativeElement.style.paddingTop);
    if (event.target.innerWidth > 370)
      this.gridview.nativeElement.style.paddingLeft = ((event.target.innerWidth % 370) / 2).toString().concat("px");
    else
      this.gridview.nativeElement.style.paddingLeft = (0).toString().concat("px");

  }
}
