import { Component, Input, OnInit } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-unavailable-list-item',
  templateUrl: './unavailable-list-item.component.html',
  styleUrls: ['./unavailable-list-item.component.sass'],
    inputs: [
    'device'
  ]
})
export class UnavailableListItemComponent implements OnInit {
  device;
  typeName;
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    const type = this.device.lampType >= 0 ? this.device.lampType : 11  //11 is plug type)
    this.typeName = this.translate.instant('devices.type' + type.toString());

  }

}
