import { Component, OnInit } from '@angular/core';
import { BlueluxDataService }  from '../../bluelux-data.service';
import {MatSpinner} from '@angular/material';
import {MatCard} from '@angular/material';
import {MatList} from '@angular/material';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.sass']
})
export class PlacesComponent implements OnInit {

  constructor(private data: BlueluxDataService) { }

  ngOnInit() {
  }

  onPlaceClicked(place){
   this.data.selectPlace(place);
   console.log(place);
}

}
