import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent }       from '../ui/login/login.component';
import { AlexaLoginComponent }       from '../ui/oauth2/alexa-login/alexa-login.component';
import { GoogleAssistantLoginComponent }       from '../ui/oauth2/google-assistant-login/google-assistant-login.component';
import { ApiLoginComponent }       from '../ui/oauth2/api-login/api-login.component';

import { PlacesComponent }    from '../ui/places/places.component'
import { DevicesComponent }    from '../ui/devices/devices.component'
import { ScenesComponent }    from '../ui/scenes/scenes.component'
import { TimeflowComponent }    from '../ui/timeflow/timeflow.component'
import { DashboardComponent }    from '../ui/dashboard/dashboard.component'
import { SettingsComponent }    from '../ui/settings/settings.component'

import { AlexaLinkComponent }       from '../ui/oauth2/alexa-link/alexa-link.component';
import { GoogleAssistantLinkComponent }       from '../ui/oauth2/google-assistant-link/google-assistant-link.component';
import { ApiLinkComponent }       from '../ui/oauth2/api-link/api-link.component';

import { AuthGuardService }  from '../auth-guard.service';
 

const routes: Routes = [
  { path: '', redirectTo: '/places', pathMatch: 'full' }, 
  { path: 'login',  component: LoginComponent },
  { path: 'alexa-login',  component: AlexaLoginComponent },
  { path: 'google-assistant-login',  component: GoogleAssistantLoginComponent },
  { path: 'api-login',  component: ApiLoginComponent },
  { path: 'alexa-link',  component: AlexaLinkComponent, canActivate: [AuthGuardService]},
  { path: 'google-assistant-link',  component: GoogleAssistantLinkComponent, canActivate: [AuthGuardService]},
  { path: 'api-link',  component: ApiLinkComponent, canActivate: [AuthGuardService]},
  { path: 'dashboard',  component: DashboardComponent, canActivate: [AuthGuardService]},
  { path: 'places',  component: PlacesComponent, canActivate: [AuthGuardService]},
  { path: 'devices',  component: DevicesComponent, canActivate: [AuthGuardService]},
  { path: 'scenes',  component: ScenesComponent, canActivate: [AuthGuardService]},
  { path: 'timeflow',  component: TimeflowComponent, canActivate: [AuthGuardService]},
  { path: 'settings',  component: SettingsComponent, canActivate: [AuthGuardService]}   
  ];


@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ] 
})

export class AppRoutingModule {}
