import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {

  @Output() onLoginStatusChanged = new EventEmitter<boolean>();
  loggedIn = false;
  year = new Date().getFullYear();
  constructor(public af: AngularFireAuth, public snackBar: MatSnackBar, private translate: TranslateService) {
    
  }
 
  ngOnInit() {

  } 

  loginWithGoogle() {
    this.af.auth.signInWithPopup(
     new firebase.auth.GoogleAuthProvider()
    ).catch(e=>{ 
      console.log("Error trying to log in with your Google Account.")
      this.openSnackBar(this.translate.instant('login.googleSignInError'));
    });
  }

  loginWithFacebook() {
    this.af.auth.signInWithPopup(
           new firebase.auth.FacebookAuthProvider()
  ).catch(e=>{
      console.log("Error trying to log in with your Facebook Account.")
      this.openSnackBar(this.translate.instant('login.facebookSignInError'));
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

}
