import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { BlueluxDataService }  from '../../../bluelux-data.service';
import { CommandManagerService }  from '../../../command-manager.service';


  /*
    Alexa Link Component
    With an authenticated user, uses the authorization code request parameters stored in data manager.

    Writes to firebase authenticationCode and redirects to redirect_uri if everything is ok.

    state, client_id, response_type, scope, and redirect_uri as query string parameters.
    The state is used by the Alexa service during the account linking process. Your page needs to keep track of this value, as you must pass it back later.
    The client_id is a value defined by you when you set up account linking for the skill in the developer portal.
    The response_type is always code for the code grant flow.
    The scope is an optional list of access scopes indicating the level of access requested. You define the set of scopes to support when enabling account linking for your skill.
    The redirect_uri is the URL to which your service redirects the user once the user is authenticated.

  */

@Component({
  selector: 'app-api-link',
  templateUrl: './api-link.component.html',
  styleUrls: ['./api-link.component.scss'],

})
export class ApiLinkComponent implements OnInit {

      state; 
      client_id;
      response_type;
      scope;
      redirect_uri;

      isLinked = false;
      returnURL = '';
      isClicked = false;
      
  @Output() onLoginStatusChanged = new EventEmitter<boolean>();
  loggedIn = false;
  selectedPlace = null;

  constructor(public af: AngularFireAuth, public snackBar: MatSnackBar, private translate: TranslateService, private activatedRoute: ActivatedRoute, private data: BlueluxDataService,private commandManager: CommandManagerService) {
    
  }

  ngOnInit() {

        this.state = this.data.alexa_state;
        this.client_id = this.data.alexa_client_id;
        this.response_type = this.data.alexa_response_type;
        this.scope = this.data.alexa_scope;
        this.redirect_uri = this.data.alexa_redirect_uri;


        if(!this.scope){
          this.scope = "std";
        }
        
        console.log('Api parameters from link already logged')
        console.log(this.state) 
        console.log(this.client_id)
        console.log(this.response_type)
        console.log(this.scope)
        console.log(this.redirect_uri)

 
  }

  allowApiLinking() {    
    if(this.selectedPlace!=null){
      this.isClicked = true
      var authCode = this.commandManager.createAuthorizationCode(); 
      var promise = this.commandManager.writeAuthorizationCode(this.redirect_uri, this.state, this.selectedPlace, this.client_id,this.scope, authCode);
      
      promise
      .then(_ =>  {
         console.log(this.redirect_uri+'?state='+this.state+'&code='.concat(authCode))
         this. returnURL = this.redirect_uri+'?state='+this.state+'&code='.concat(authCode);            
         //location.href  = this.redirect_uri+'?state='+this.state+'&code='.concat(authCode);           
         this.isLinked = true;
        }
      )
      .catch(err => console.log(err, 'Error creating authorizationCode!'));

        setTimeout(() => {
            this.openSnackBar(this.translate.instant('api-link.error'));
            this.isClicked = false
        }, 15000);
      //Timeout Spinner within 15 seconds

    }else{
      //Show dialog asking place
      this.openSnackBar(this.translate.instant('api-link.selectPlace'));

    }
  }

  changeUser() {    
    this.data.clear();
    this.af.auth.signOut();    
  }  

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

}
