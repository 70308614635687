import { Pipe, PipeTransform } from '@angular/core';

import { NgModule } from '@angular/core';

@NgModule({
    exports:[ShutterStatePipe] // <---
  })

@Pipe({name: 'shutterState'})
export class ShutterStatePipe implements PipeTransform {
  transform(stateVal: number): string {
    let state = 'Entreaberta'
    switch(stateVal){
      case 0:
        state = "Fechada"
        break;
      case 1:
        //closing
        state = "Fechando..."
        break;
      case 2:
        //opening
        state = "Abrindo..."
        break;
      case 3:
        //stopped
        state = "Entreaberta"
        break;
      case 25:
        state = "25%"
        break;
      case 50:
        state = "50%"
        break;
      case 75:
        state = "75%"
        break;
      case 255:
        state = "Aberta"
        break;
      default:
        state = "Entreaberta"
    }
    return state
  }
}

