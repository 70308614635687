import { Component, OnInit } from '@angular/core';
import { CommandManagerService }  from '../../../../command-manager.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-scene-list-item',
  templateUrl: './scene-list-item.component.html',
  styleUrls: ['./scene-list-item.component.sass'],
    inputs: [
    'scene'
  ]
})
export class SceneListItemComponent implements OnInit {
  scene;
  constructor(private commandManager: CommandManagerService, private translate: TranslateService) { }
  
  ngOnInit() {
  }

    onActivateButtonPressed(){
      console.log("Button Pressed!!")
      this.commandManager.sendSceneCommand(this.scene.ownKey.toString());
  }



}
