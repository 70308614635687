import * as firebase from 'firebase/app';

import { AngularFireDatabase, AngularFireDatabaseModule, AngularFireList } from '@angular/fire/database';
import {GarageDoorButtonStatePipe, GarageDoorStatePipe} from './ui/devices/list-components/garage-door-list-item/garage-door.pipe';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import { AlexaLinkComponent } from './ui/oauth2/alexa-link/alexa-link.component';
import { AlexaLoginComponent } from './ui/oauth2/alexa-login/alexa-login.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { ApiLinkComponent } from './ui/oauth2/api-link/api-link.component';
import { ApiLoginComponent } from './ui/oauth2/api-login/api-login.component';
import { AppComponent } from './app.component';
import { AppRoutingModule }  from './app-routing-module/app-routing-module.module';
import { AuthGuardService }  from './auth-guard.service';
import { BlueluxDataService }  from './bluelux-data.service';
import { BlueluxOriginalListItemComponent } from './ui/devices/list-components/bluelux-original-list-item/bluelux-original-list-item.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommandManagerService }  from './command-manager.service';
import { DashboardComponent } from './ui/dashboard/dashboard.component';
import { DeviceListItemComponent } from './ui/devices/list-components/device-list-item/device-list-item.component';
import { DevicesComponent } from './ui/devices/devices.component';
import { DialogAcIrComponent } from './ui/devices/list-components/ir-list-item/dialog-ac-ir/dialog-ac-ir.component';
import { DialogOnOffIrComponent } from './ui/devices/list-components/ir-list-item/dialog-on-off-ir/dialog-on-off-ir.component';
import { DialogTvIrComponent } from './ui/devices/list-components/ir-list-item/dialog-tv-ir/dialog-tv-ir.component';
import { DimmableListItemComponent } from './ui/devices/list-components/dimmable-list-item/dimmable-list-item.component';
import { DoorListItemComponent } from './ui/devices/list-components/door-list-item/door-list-item.component';
import {DoorStatePipe} from './ui/devices/list-components/door-list-item/door.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { GarageDoorListItemComponent } from './ui/devices/list-components/garage-door-list-item/garage-door-list-item.component';
import { GoogleAssistantLinkComponent } from './ui/oauth2/google-assistant-link/google-assistant-link.component';
import { GoogleAssistantLoginComponent } from './ui/oauth2/google-assistant-login/google-assistant-login.component';
import { IRListItemComponent } from './ui/devices/list-components/ir-list-item/ir-list-item.component';
import {IRStatePipe} from './ui/devices/list-components/ir-list-item/ir.pipe';
import { LoginComponent } from './ui/login/login.component';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from "@angular/material/icon";
import {MatIconRegistry} from '@angular/material/icon';
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { NgModule } from '@angular/core';
import { PlacesComponent } from './ui/places/places.component';
import { RouterModule }   from '@angular/router';
import { SceneListItemComponent } from './ui/scenes/list-components/scene-list-item/scene-list-item.component';
import { ScenesComponent } from './ui/scenes/scenes.component';
import { SettingsComponent } from './ui/settings/settings.component';
import { ShutterListItemComponent } from './ui/devices/list-components/shutter-list-item/shutter-list-item.component';
import {ShutterStatePipe} from './ui/devices/list-components/shutter-list-item/shutter.pipe';
import { TimeflowComponent } from './ui/timeflow/timeflow.component';
import { TimeflowListItemComponent } from './ui/timeflow/timeflow-list-item/timeflow-list-item.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { UnavailableListItemComponent } from './ui/devices/list-components/unavailable-list-item/unavailable-list-item.component';
import { enableProdMode } from '@angular/core';

// BLUELUX WEB APP MODULE
// WE MUST ENABLE PRODUCTION MODE BEFORE PUBLISHING
enableProdMode();








// Must export the config
export const firebaseConfigBlueluxWebAppDebug = {
    apiKey: "AIzaSyCXWk8QhVvuUcYum0ng1U117j2-m0y7VXY",
    authDomain: "bluelux-web-app-debug.firebaseapp.com",
    databaseURL: "https://bluelux-web-app-debug-default-rtdb.firebaseio.com",
    storageBucket: "bluelux-web-app-debug.appspot.com",
    messagingSenderId: "441668330202"
  };

export const firebaseConfigProd = {

  apiKey: "AIzaSyBefyq9pGyH8m6sdb1GTKax-KNQs2Ke_8I",
    authDomain: "blueluxcloud.firebaseapp.com",
    databaseURL: "https://blueluxcloud.firebaseio.com",
    projectId: "blueluxcloud",
    storageBucket: "blueluxcloud.appspot.com",
    messagingSenderId: "148556592182"
}


/*const firebaseAuthConfig = {
  provider: AuthProviders.Google,
  method: AuthMethods.Redirect
};*/

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  entryComponents:[DialogAcIrComponent,DialogTvIrComponent,DialogOnOffIrComponent],

  declarations: [
    AppComponent,
    LoginComponent,
    AlexaLoginComponent,
    GoogleAssistantLoginComponent,
    ApiLoginComponent,
    PlacesComponent,
    DevicesComponent,
    BlueluxOriginalListItemComponent,
    DeviceListItemComponent,
    UnavailableListItemComponent,
    DimmableListItemComponent,
    ShutterListItemComponent,
    GarageDoorListItemComponent,
    GarageDoorStatePipe,
    GarageDoorButtonStatePipe,
    DoorStatePipe,
    IRListItemComponent,
    IRStatePipe,
    ShutterStatePipe,
    DoorListItemComponent,
    ScenesComponent,
    TimeflowComponent,
    SceneListItemComponent,
    TimeflowListItemComponent,
    DashboardComponent,
    SettingsComponent,
    AlexaLinkComponent,
    GoogleAssistantLinkComponent,
    ApiLinkComponent,
    DialogAcIrComponent,
    DialogTvIrComponent,
    DialogOnOffIrComponent,


  ],
  imports: [
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatListModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatButtonModule,
    FlexLayoutModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
      provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
      } 
    }),
    AngularFireModule.initializeApp(firebaseConfigProd),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
  ],
  providers: [
    AuthGuardService,
    BlueluxDataService,
    CommandManagerService
  ],
  
  bootstrap: [AppComponent] 
})
export class AppModule { 

}
