import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { CommandManagerService } from '../../../command-manager.service';
import { BlueluxDataService } from '../../../bluelux-data.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-timeflow-list-item',
  templateUrl: './timeflow-list-item.component.html',
  styleUrls: ['./timeflow-list-item.component.scss'],
  inputs: [
    'timeflow'
  ]
})
export class TimeflowListItemComponent implements AfterViewInit {
  timeflow;


  @ViewChild('bgColorSun', {static: false}) bgColorSun;
  @ViewChild('bgColorMon', {static: false}) bgColorMon;
  @ViewChild('bgColorTue', {static: false}) bgColorTue;
  @ViewChild('bgColorWed', {static: false}) bgColorWed;
  @ViewChild('bgColorThu', {static: false}) bgColorThu;
  @ViewChild('bgColorFri', {static: false}) bgColorFri;
  @ViewChild('bgColorSat', {static: false}) bgColorSat;


  constructor(private commandManager: CommandManagerService, private data: BlueluxDataService, public snackBar: MatSnackBar) { }

  ngAfterViewInit() {
    if (this.timeflow.sun){
      this.bgColorSun.nativeElement.style.backgroundColor = "#2196f3";
      this.bgColorSun.nativeElement.style.color = "white";
    }

    if (this.timeflow.mon){
      this.bgColorMon.nativeElement.style.backgroundColor = "#2196f3";
      this.bgColorMon.nativeElement.style.color = "white";
    }

    if (this.timeflow.tue){
      this.bgColorTue.nativeElement.style.backgroundColor = "#2196f3";
      this.bgColorTue.nativeElement.style.color = "white";
    }

    if (this.timeflow.wed){
      this.bgColorWed.nativeElement.style.backgroundColor = "#2196f3";
      this.bgColorWed.nativeElement.style.color = "white";
    }

    if (this.timeflow.thu){
      this.bgColorThu.nativeElement.style.backgroundColor = "#2196f3";
      this.bgColorThu.nativeElement.style.color = "white";
    }

    if (this.timeflow.fri){
      this.bgColorFri.nativeElement.style.backgroundColor = "#2196f3";
      this.bgColorFri.nativeElement.style.color = "white";
    }

    if (this.timeflow.sat){
      this.bgColorSat.nativeElement.style.backgroundColor = "#2196f3";
      this.bgColorSat.nativeElement.style.color = "white";
    }

  }


  onSwitch($event) {
    console.log("Switched!!")
    var checked = $event.checked;
    this.timeflow.enabled = checked;
    if(checked){
            this.openSnackBar('Timeflow '+ this.timeflow.name + ' (' + this.getTime()  +'h) ativado.');
    }else{
            this.openSnackBar('Timeflow '+ this.timeflow.name + ' (' + this.getTime()  +'h) desativado.');
    }
    this.commandManager.updateTimeflow(this.timeflow);
    
  }

    openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 5000,
    });
  }

  getTime(): string {
    var hour = this.timeflow.hour.toString();
    var minute = this.timeflow.minute.toString();

    if (this.timeflow.hour < 10) {
      hour = "0".concat(hour);
    }

    if (this.timeflow.minute < 10) {
      minute = "0".concat(minute);
    }

    var time = hour.concat(":").concat(minute);
    return time;
  }

}
