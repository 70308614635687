import { Pipe, PipeTransform } from '@angular/core';

import { NgModule } from '@angular/core';

@NgModule({
    exports:[DoorStatePipe] // <---
  })

@Pipe({name: 'doorState'})
export class DoorStatePipe implements PipeTransform {
  transform(stateVal: number): string {
    let state = 'Indeterminado'
    switch(stateVal){
      case 0:
        state = 'Fechado'
        break;
      case 255:
        state = 'Aberto'
        break;
    }
    return state
  }
}