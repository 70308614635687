import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import {  Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { AuthGuardService }  from './auth-guard.service';
import { BlueluxDataService }  from './bluelux-data.service';
import {MatSidenav} from '@angular/material';
import {MatSelect} from '@angular/material';
import {MatToolbar} from '@angular/material';
import {MatMenu} from '@angular/material';
import {MatIconRegistry} from '@angular/material/icon';

import { BrowserModule } from '@angular/platform-browser';

import { isDevMode } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

@ViewChild('sidenav', {static: false}) sidenav: MatSidenav;

devMode = true;

constructor(public af: AngularFireAuth, public translate: TranslateService, 
                private authGuard : AuthGuardService, private data: BlueluxDataService,
                 public vcr: ViewContainerRef, private router: Router) {

if(isDevMode()){
  console.log("BLUELUX WEB APP --------- DEVELOPMENT MODE");
}else{
  console.log = function() {}
  this.devMode = false;
  
}
  
 translate.addLangs(["pt-br", "en"]);
 translate.setDefaultLang('pt-br');

 let browserLang = translate.getBrowserLang();         
 translate.use(browserLang.match(/pt-br|en/) ? browserLang : 'pt-br');
}

logout() {
    this.data.clear();
    this.af.auth.signOut();
}

toggleDrawer(){
    this.sidenav.toggle();
}

setSelectedPlace(place){
    console.log("Local selecionado")
    this.data.selectPlace(place)
    this.sidenav.close();
}

onDashboardSelected(){
  this.router.navigateByUrl('/dashboard');
      this.sidenav.close();
}

onLightingSelected(){
  this.router.navigateByUrl('/devices');
      this.sidenav.close();
}

onSpecialSelected(){
  this.router.navigateByUrl('/special');
      this.sidenav.close();

}

onScenesSelected(){
  this.router.navigateByUrl('/scenes');
      this.sidenav.close();

}

onTimeflowSelected(){
  this.router.navigateByUrl('/timeflow');
      this.sidenav.close();
}

onSettingsSelected(){
  this.router.navigateByUrl('/settings');
      this.sidenav.close();
}

onHelpSelected(){
      location.replace("https://www.bluelux.com.br/contato/")

      this.sidenav.close();
}


}
