import { Component, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { BlueluxDataService }  from '../../bluelux-data.service';

@Component({
  selector: 'app-timeflow',
  templateUrl: './timeflow.component.html',
  styleUrls: ['./timeflow.component.sass']
})
export class TimeflowComponent implements AfterViewInit {
  mon;
  tue;
  wed;
  thu;
  fri;
  sat;
  sun;
 
  @ViewChild('gridview', {static: false}) gridview; 

  constructor(private data: BlueluxDataService) { }

  ngAfterViewInit() {
    if(window.innerWidth > 370){
      this.gridview.nativeElement.style.paddingLeft = ((window.innerWidth % 370)/2).toString().concat("px");
    }
    else{
      this.gridview.nativeElement.style.paddingLeft = (0).toString().concat("px");
    }


    this.mon;
    this.tue;
    this.wed;
    this.thu;
    this.fri;
    this.sat;
    this.sun;
  }

@HostListener('window:resize', ['$event'])
onResize(event) {

  //console.log(event.target.innerWidth);
  //console.log(this.gridview.nativeElement.style.paddingTop);
  
if(event.target.innerWidth>370)
  this.gridview.nativeElement.style.paddingLeft = ((event.target.innerWidth % 370)/2).toString().concat("px");
else
  this.gridview.nativeElement.style.paddingLeft = (0).toString().concat("px");

}


}
