import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { CommandManagerService }  from '../../../../command-manager.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dimmable-list-item',
  templateUrl: './dimmable-list-item.component.html',
  styleUrls: ['./dimmable-list-item.component.sass'],
   inputs: [
    'device'
  ]
})
export class DimmableListItemComponent implements AfterViewInit {
  device;
  typeName; 
  @ViewChild('bgColor', {static: false}) bgColor; 


  constructor(private commandManager: CommandManagerService, private translate: TranslateService) { }
  
  ngAfterViewInit() {
    this.typeName = this.translate.instant('devices.type' + this.device.lampType.toString());
    const color = this.device.lampBgColor >= 0 ? this.device.lampBgColor : 0
    this.bgColor.nativeElement.style.backgroundColor = this.translate.instant('devices.bgColor' + color.toString());
  }

  onSwitch($event){
      console.log("Switched!!")
      var checked = $event.checked;
      if(checked){
        this.device.brightness = 255;   
      }else{
        this.device.brightness = 0;
      } 
       
      this.commandManager.sendBrightnessCommand(this.device.ownKey,this.device.brightness);
  }

  onSliderChanged($event){
      console.log("Slider changed!!")      
      console.log($event);
      this.device.brightness = $event.value;   
      this.commandManager.sendBrightnessCommand(this.device.ownKey,this.device.brightness);
      
  }

}
