import { Pipe, PipeTransform } from '@angular/core';

import { NgModule } from '@angular/core';

@NgModule({
    exports:[IRStatePipe] // <---
  })

@Pipe({name: 'irState'})
export class IRStatePipe implements PipeTransform {
  transform(stateVal: number): string {
    if(stateVal>0){
      return 'Último comando: Ligado'
    }else{
      return 'Último comando: Desligado'
    }
  }
}