import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { BlockScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import { CommandManagerService }  from '../../../../command-manager.service';
import { DialogAcIrComponent } from './dialog-ac-ir/dialog-ac-ir.component';
import { DialogOnOffIrComponent } from './dialog-on-off-ir/dialog-on-off-ir.component';
import { DialogTvIrComponent } from './dialog-tv-ir/dialog-tv-ir.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ir-list-item',
  templateUrl: './ir-list-item.component.html',
  styleUrls: ['./ir-list-item.component.sass'],
  inputs: [
    'device'
  ]
})

export class IRListItemComponent implements AfterViewInit {
  device;
  typeName; 
  @ViewChild('bgColor', {static: false}) bgColor; 


  constructor(private commandManager: CommandManagerService, private translate: TranslateService, private matDialog: MatDialog) { }
  
  ngAfterViewInit() {
    this.typeName = this.translate.instant('devices.type' + this.device.lampType.toString());
    const color = this.device.lampBgColor >= 0 ? this.device.lampBgColor : 0
    this.bgColor.nativeElement.style.backgroundColor = this.translate.instant('devices.bgColor' + color.toString());
    }

   TYPE_AIR_COND = 7;
   TYPE_TV = 8;
   TYPE_GENERIC_IR_DEVICE = 17;


  openRemoteControl(){
        /*const modDevice = this.device
    modDevice.lampType = 13
    modDevice.learnedIRKeys = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,29,30,31,32,32,33,34,35,36,37,38]
    modDevice.learnedIRKeys = []*/
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'panelBG'
   
    let dialogComponent : any
    switch(this.device.lampType){
      case this.TYPE_AIR_COND:
        dialogComponent = DialogAcIrComponent
        break;
      case this.TYPE_TV:
        dialogComponent = DialogTvIrComponent
        break;
      default:
        dialogComponent = DialogOnOffIrComponent
        break;
    }

    const dialog = this.matDialog.open(dialogComponent,{
      data:  this.device,
      panelClass: 'panelBG',
      autoFocus: false
    });
  }

}
