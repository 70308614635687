import { Component, Inject, OnInit } from '@angular/core';

import { CommandManagerService }  from '../../../../../command-manager.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-ac-ir',
  templateUrl: './dialog-ac-ir.component.html',
  styleUrls: ['./dialog-ac-ir.component.sass']
})
export class DialogAcIrComponent{
  device;

  constructor(public dialogRef: MatDialogRef<DialogAcIrComponent>, private commandManager: CommandManagerService,  @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.device = data
  }
  

  openRemoteControl(index: number){
   this.commandManager.sendBrightnessCommand(this.device.ownKey,index);
  }
  
  
}
