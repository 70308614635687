import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-device-list-item',
  templateUrl: './device-list-item.component.html',
  styleUrls: ['./device-list-item.component.sass'],
  inputs: [
    'device'
  ]
})
export class DeviceListItemComponent implements OnInit {
  device;
  constructor( ) { }

  ngOnInit() {
  }

}
