import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { CommandManagerService }  from '../../../../command-manager.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-bluelux-original-list-item',
  templateUrl: './bluelux-original-list-item.component.html',
  styleUrls: ['./bluelux-original-list-item.component.sass'],
  inputs: [
    'device'
  ]
})
export class BlueluxOriginalListItemComponent implements AfterViewInit {
  device;
  typeName; 
  @ViewChild('bgColor', {static: false}) bgColor; 


  constructor(private commandManager: CommandManagerService, private translate: TranslateService) { }
  
  ngAfterViewInit() {
    const type = this.device.lampType >= 0 ? this.device.lampType : 11  //11 is plug type)
    this.typeName = this.translate.instant('devices.type' + type.toString());
    const color = this.device.lampBgColor >= 0 ? this.device.lampBgColor : 0
    this.bgColor.nativeElement.style.backgroundColor = this.translate.instant('devices.bgColor' + color.toString());
  }

  onSwitch($event){
      console.log("Switched!!")
      var checked = $event.checked;
      if(checked){
        this.commandManager.sendBrightnessCommand(this.device.ownKey,255);
      }else{
        this.commandManager.sendBrightnessCommand(this.device.ownKey,0);
      }       
  }

}
