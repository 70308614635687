import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';

import { BlueluxDataService }  from '../../bluelux-data.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.sass']
})
export class DevicesComponent implements AfterViewInit {

  @ViewChild('gridview', {static: false}) gridview; 


  constructor(private data: BlueluxDataService) { }

  ngAfterViewInit() {
    if(window.innerWidth > 330)
      this.gridview.nativeElement.style.paddingLeft = ((window.innerWidth % 330)/2).toString().concat("px");
    else
      this.gridview.nativeElement.style.paddingLeft = ( (window.innerWidth-280)/2 ).toString().concat("px");
  }

@HostListener('window:resize', ['$event'])
onResize(event) {
  console.log(event.target.innerWidth);
  //console.log((event.target.innerWidth/348)/2);
console.log(this.gridview.nativeElement.style.paddingTop);
if(event.target.innerWidth>330)
  this.gridview.nativeElement.style.paddingLeft = ((event.target.innerWidth % 330)/2).toString().concat("px");
else
  this.gridview.nativeElement.style.paddingLeft = ((window.innerWidth-280)/2).toString().concat("px");

}

}
