import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import {
  CanActivate, ActivatedRouteSnapshot,
  RouterStateSnapshot, Router
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { take } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate {


  loggedIn = false;
  offline = true; 

  isLoggedIn() : boolean {
    return this.loggedIn;
  }


  isOnline(){
    this.offline = !(navigator.onLine === false);
    console.log(this.offline);
    window.addEventListener('offline', function(){
        console.log('Now im offline');
    });
    window.addEventListener('online', function(){
        console.log('Now im online');
    });
  }
 

  constructor(public af: AngularFireAuth, private router: Router) { 

    this.af.authState.subscribe(    auth => {
        console.log('Auth status changed');
        console.log(auth)
        
        /*console.log(this.router.url);
         console.log(this.router.url.includes('alexa-login'))
        console.log('isOnline?');
        console.log(this.isOnline());*/

        if (auth != null) {
          this.loggedIn = true;
          console.log('AUTH GUARD URL!!!!');
          console.log(this.router.url);
          console.log(this.router.url.includes('alexa-login'))
          console.log(this.router.url.includes('google-assistant-login'))


          if(this.router.url.includes('alexa-login')){
            this.router.navigateByUrl('/alexa-link');
          }else{
            if(this.router.url.includes('google-assistant-login')){
              this.router.navigateByUrl('/google-assistant-link');
            }else{
              if(this.router.url.includes('api-login')){
                this.router.navigateByUrl('/api-link');
              }else{
                if( !( this.router.url.includes('alexa-login') ||  this.router.url.includes('google-assistant-login') || this.router.url.includes('api-login') )  )
                  this.router.navigateByUrl('/places');
                }
            }
          }
        } else {
          if(this.isLoggedIn){
            this.loggedIn = false;
            if(!( this.router.url.includes('alexa-login') ||  this.router.url.includes('google-assistant-login') ||  this.router.url.includes('api-login') ) )
              this.router.navigateByUrl('/login');
          }
        }
      }

    );



  }
 canActivate(): Observable<boolean> | boolean {
    // here it checks if this is first call. If not, return 
    // simple boolean based on user object from authService
    // otherwise:

    return this.af.authState.pipe(take(1)).pipe(map(auth => {
      console.log("auth:" + auth);
      if (!auth) {
         if(!( this.router.url.includes('alexa-login') ||  this.router.url.includes('google-assistant-login') ||  this.router.url.includes('api-login')))
           this.router.navigateByUrl('/login');
        return true;
      }
      return true;
    }));
  } 

}
