import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { CommandManagerService }  from '../../../../command-manager.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-garage-door-list-item',
  templateUrl: './garage-door-list-item.component.html',
  styleUrls: ['./garage-door-list-item.component.sass'],
  inputs: [
    'device'
  ]
})

export class GarageDoorListItemComponent implements AfterViewInit {
  device;
  typeName; 
  @ViewChild('bgColor', {static: false}) bgColor; 


  constructor(private commandManager: CommandManagerService, private translate: TranslateService) { }
  
  ngAfterViewInit() {
    this.typeName = this.translate.instant('devices.type' + this.device.lampType.toString());
    const color = this.device.lampBgColor >= 0 ? this.device.lampBgColor : 0
    this.bgColor.nativeElement.style.backgroundColor = this.translate.instant('devices.bgColor' + color.toString());
  }
  
  switch(){
    if(this.device.brightness == 0){
      this.commandManager.sendBrightnessCommand(this.device.ownKey,255);
    }else{
      if(this.device.brightness == 127){
        this.commandManager.sendBrightnessCommand(this.device.ownKey,127);
      }else{
        if(this.device.brightness == 255){
          this.commandManager.sendBrightnessCommand(this.device.ownKey,0);
        }
      }
    }
  }
  
}
