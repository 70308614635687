import 'rxjs/add/operator/first';

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import {
  Router
} from '@angular/router';

@Injectable()
export class BlueluxDataService {

  uid = null;
  firebaseAuthData = null;
  userObservable = null;
  user = null;

  placeKeys = null;
  places = [];

  selectedPlace = null;
  selectedObserver = null;

  devicesObserver = null;
  devices = null;

  statesObserver = null;
  states = null;

  scenesObserver = null;
  scenes = null;

  timeflowsObserver = null;
  timeflows = null;
  timeflowDays = null;  


  alexa_state = null;
  alexa_client_id = null;
  alexa_response_type = null;
  alexa_scope = null;
  alexa_redirect_uri = null;



  constructor(public af: AngularFireAuth, public db: AngularFireDatabase, private router: Router) {
    this.af.authState.subscribe(auth => {
      console.log('Auth status changed');
      console.log(auth)
      if (auth != null) {
        this.uid = auth.uid;
        this.firebaseAuthData = auth;
        this.loadUser();
      } else {

      }
    });

  }

  loadUser() {
    if (this.uid != null) {
      this.userObservable = this.db.object('/users/'.concat(this.uid)).snapshotChanges();
      this.userObservable.subscribe(snapshot => {
        console.log("Bluelux User Data Loaded");
        console.log(snapshot.key);
        this.user = snapshot.payload.val(); 
        this.user.name = this.firebaseAuthData.displayName;
        this.user.email = this.firebaseAuthData.email;
        this.user.photoURL = this.firebaseAuthData.photoURL;
        console.log(this.user);
        console.log("Places:");
        console.log(this.user.places);
        this.placeKeys = Object.keys(this.user.places);
        console.log(this.placeKeys);
        this.loadPlaces();

      });

    } else {
      //TODO: Handle exception (Should logout and warn user about the issue, loggin the error to development team)
    }
  }

  loadPlaces() {
    this.places = [];
    for (let placeKey of this.placeKeys) {
      console.log(placeKey);
      var sub = this.db.object('/places/'.concat(placeKey)).snapshotChanges().first().subscribe(placeSnapshot => {
          var place : any =  placeSnapshot.payload.val();
          place.ownKey = placeKey;
          console.log(place);
          this.places.push(place);
          console.log(this.places)
        });
    }
    console.log(this.places);
  }


  selectPlace(place) {
    if (this.selectedObserver != null)
      this.selectedObserver.unsubscribe();


    this.selectedPlace = place;
    var placeKey = place.ownKey;
    console.log('PlaceKey: ' + placeKey);
    this.selectedObserver = this.db.object('/places/'.concat(placeKey)).snapshotChanges().subscribe(placeSnapshot => {
        var place : any = placeSnapshot.payload.val();
        place.ownKey = placeKey;
        this.selectedPlace = place;
        console.log(this.selectedPlace); 
        if (this.selectedPlace.users[this.uid].role == 'admin') {
          this.selectedPlace.admin = true;
        } else {
          this.selectedPlace.admin = false;
        }

        this.loadPlaceData();
        console.log("Recebi alteração");
      });
    this.router.navigateByUrl('/devices');
  }

  loadPlaceData() {
    this.loadDevices();
    this.loadDeviceStates();
    this.loadScenes();
    this.loadTimeflows();


  }

  loadDevices() {
    if (this.devicesObserver != null)
      this.devicesObserver.unsubscribe();

    this.devices = [];


    this.devicesObserver = this.selectedObserver = this.db.object('/devices/'.concat(this.selectedPlace.ownKey)).snapshotChanges().subscribe(devicesArraySnapshot => {

        this.devices = Object.keys(devicesArraySnapshot.payload.val()).filter(function (key) {
          // Removing trashed devices
          return !devicesArraySnapshot.payload.val()[key].trash;
        }).map(function (key) {
          var device = devicesArraySnapshot.payload.val()[key];
          device.ownKey = key;
          device.available = false;
          return device
        });

    var device = Object();
    device.name = "Iluminação geral";
    device.deviceId = 0;
    device.lampBgColor = 0;
    device.lampType = 0;
    device.ownKey = 0;
    //this.devices.unshift(device);
    
        if (this.states != null)
          this.reloadDeviceStates();

        console.log("Devices data");
        console.log(this.devices);

      });
  }

  loadDeviceStates() {
    if (this.statesObserver != null)
      this.statesObserver.unsubscribe();


    this.states = [];
    this.statesObserver = this.selectedObserver = this.db.object('/states/'.concat(this.selectedPlace.ownKey)).snapshotChanges().subscribe(statesArraySnapshot => {

        this.states = Object.keys(statesArraySnapshot.payload.val()).map(function (key) {
          var state = statesArraySnapshot.payload.val()[key];
          state.ownKey = key;
          return state
        });

        this.reloadDeviceStates();



        console.log("States data");
        console.log(this.states);
        console.log("Devices data");
        console.log(this.devices);

      });
  }

  reloadDeviceStates() {
    var anyAvailable = false;

    if (this.devices != null && this.states != null) {
      for (let device of this.devices) {
        for (let state of this.states) {
          if (device.ownKey == state.ownKey) {
            device.available = state.available;
            device.brightness = state.brightness;
            device.dimmable = state.dimmable;

            if(state.available){
              anyAvailable = true;
            }
          }
        }
      }
    }

    //this.devices[0].available = anyAvailable;
    

  }

  loadScenes() {
    
    if (this.scenesObserver != null)
      this.scenesObserver.unsubscribe();

    this.scenes = [];
    this.scenesObserver = this.selectedObserver = this.db.object('/scenes/'.concat(this.selectedPlace.ownKey)).snapshotChanges().subscribe(scenesArraySnapshot => {

      if(scenesArraySnapshot.payload.val() != null){

        this.scenes = Object.keys(scenesArraySnapshot.payload.val()).map(function (key) {
          var scene = scenesArraySnapshot.payload.val()[key];
          scene.ownKey = key;
          return scene;
        });
      }else{
        this.scenes = []
      }

        console.log("Scenes data");
        console.log(this.scenes);

      });
    
  }

  loadTimeflows() {
    if (this.timeflowsObserver != null)
      this.timeflowsObserver.unsubscribe();

    this.timeflows = [];
    this.timeflowDays = [];

    this.timeflowsObserver = this.selectedObserver = this.db.object('/timeflows/'.concat(this.selectedPlace.ownKey)).snapshotChanges().subscribe(timeflowsArraySnapshot => {

      if(timeflowsArraySnapshot.payload.val() != null){
        var placeTimeflows = Object.keys(timeflowsArraySnapshot.payload.val()).map(function (key) {
          var placeTimeflow = timeflowsArraySnapshot.payload.val()[key];
          console.log(placeTimeflow);
          return placeTimeflow;
        });

        this.timeflows = [];

        for (let placeTimeflow of placeTimeflows) {
          var placeKeys = Object.keys(placeTimeflow);
          for (let key of placeKeys) {
            var timeflow = placeTimeflow[key];
            timeflow.ownKey = key;
            this.setBooleanDaysOfWeek(timeflow);
            this.timeflows.push(timeflow);
          }
        }

        if(this.timeflows !=null){
        this.timeflows.sort(this.sortTimeflowFunction);
        if(this.timeflowDays.mon !=null)
          this.timeflowDays.mon.sort(this.sortTimeflowFunction);
        if(this.timeflowDays.tue !=null)
          this.timeflowDays.tue.sort(this.sortTimeflowFunction);
        if(this.timeflowDays.wed !=null)
          this.timeflowDays.wed.sort(this.sortTimeflowFunction);
        if(this.timeflowDays.thu !=null)
          this.timeflowDays.thu.sort(this.sortTimeflowFunction);
        if(this.timeflowDays.fri !=null)
          this.timeflowDays.fri.sort(this.sortTimeflowFunction);
        if(this.timeflowDays.sat !=null)
          this.timeflowDays.sat.sort(this.sortTimeflowFunction);
        if(this.timeflowDays.sun !=null)
          this.timeflowDays.sun.sort(this.sortTimeflowFunction);
        }
        console.log("Timeflow data");
        console.log(this.timeflows);
      }else{
        this.timeflows = [];
      }

      });
  }

  sortTimeflowFunction = function (a,b){
             // compare hours first
            if (a.hour < b.hour) return -1;
            if (a.hour > b.hour) return 1;

            // else a.hour === b.hour, so compare minutes to break the tie
            if (a.minute < b.minute) return -1;
            if (a.minute > b.minute) return 1;

            // couldn't break the tie
            return 0;
  }

  setBooleanDaysOfWeek(timeflow) {
    if (timeflow.daysOfWeek.indexOf(0)  > -1) {
      timeflow.sun = true
      if(this.timeflowDays.sun == null){
        this.timeflowDays.sun = [];
      }
      this.timeflowDays.sun.push(timeflow);
    } else {
      timeflow.sun = false
    }
    if (timeflow.daysOfWeek.indexOf(1)  > -1) {
      timeflow.mon = true
      if(this.timeflowDays.mon == null){
        this.timeflowDays.mon = [];
      }
      this.timeflowDays.mon.push(timeflow);
    } else {
      timeflow.mon = false
    }
    if (timeflow.daysOfWeek.indexOf(2)  > -1) {
      timeflow.tue = true
      if(this.timeflowDays.tue == null){
        this.timeflowDays.tue = [];
      }
      this.timeflowDays.tue.push(timeflow);
    } else {
      timeflow.tue = false
    }
    if (timeflow.daysOfWeek.indexOf(3)  > -1) {
      timeflow.wed = true
      if(this.timeflowDays.wed == null){
        this.timeflowDays.wed = [];
      }
      this.timeflowDays.wed.push(timeflow);
    } else {
      timeflow.wed = false
    }
    if (timeflow.daysOfWeek.indexOf(4)  > -1) {
      timeflow.thu = true
      if(this.timeflowDays.thu == null){
        this.timeflowDays.thu = [];
      }
      this.timeflowDays.thu.push(timeflow);
    } else {
      timeflow.thu = false
    }
    if (timeflow.daysOfWeek.indexOf(5)  > -1) {
      timeflow.fri = true
      if(this.timeflowDays.fri == null){
        this.timeflowDays.fri = [];
      }
      this.timeflowDays.fri.push(timeflow);
    } else {
      timeflow.fri = false
    }
    if (timeflow.daysOfWeek.indexOf(6)  > -1) {
      timeflow.sat = true
      if(this.timeflowDays.sat == null){
        this.timeflowDays.sat = [];
      }
      this.timeflowDays.sat.push(timeflow);
    } else {
      timeflow.sat = false
    }
  }

  clear() {
    this.uid = null;
    this.firebaseAuthData = null;
    this.userObservable = null;
    this.user = null;

    this.placeKeys = null;
    this.places = [];

    this.selectedPlace = null;

    this.selectedObserver = null;
    this.devicesObserver = null;
    this.devices = null;

    this.statesObserver = null;
    this.states = null;

    this.scenesObserver = null;
    this.scenes = null;

    this.timeflowsObserver = null;
    this.timeflows = null;
  }

}
