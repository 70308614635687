import { Pipe, PipeTransform } from '@angular/core';

import { NgModule } from '@angular/core';

@Pipe({name: 'garageDoorButtonState'})
export class GarageDoorButtonStatePipe implements PipeTransform {
  transform(stateVal: number): string {
    let state = 'Indeterminado'
    switch(stateVal){
      case 0:
        state = 'Abrir'
        break;
      case 127:
        state = 'Parar ou Acionar'
        break;
      case 255:
        state = 'Fechar'
        break;
    }
    return state
  }
}



@NgModule({
  exports:[GarageDoorStatePipe,GarageDoorButtonStatePipe] // <---
})

@Pipe({name: 'garageDoorState'})
export class GarageDoorStatePipe implements PipeTransform {
  transform(stateVal: number): string {
    let state = 'Indeterminado'
    switch(stateVal){
      case 0:
        state = 'Fechado'
        break;
      case 127:
        state = 'Entreaberto'
        break;
      case 255:
        state = 'Aberto'
        break;
    }
    return state
  }
}

